// extracted by mini-css-extract-plugin
export var filterBar = "help-module--filterBar--630cc";
export var help = "help-module--help--6ba21";
export var helpimg = "help-module--helpimg--3f574";
export var helplink = "help-module--helplink--9edcc";
export var helptext = "help-module--helptext--af8f7";
export var holder = "help-module--holder--dbdeb";
export var imgholder = "help-module--imgholder--46542";
export var sideholder = "help-module--sideholder--8f411";
export var spacer = "help-module--spacer--d8d89";
export var title = "help-module--title--6c371";
export var tutorialholder = "help-module--tutorialholder--5ae55";